<template>
  <form action="" class="reset-pass__form">
    <template v-if="!isRequestSent">
      <h2 class="reset-pass__form-heading">Vous avez oublié votre mot de passe ?</h2>
      <p class="reset-pass__form-paragraph">Nous vous aiderons à le réinitialiser et à vous remettre sur la bonne voie.</p>
      <input class="reset-pass__form-input" placeholder="Entrer votre email" :required="false" v-model="email" />
      <atom-button 
        class="reset-pass__form-button" 
        variant="primary md full"
        @click.prevent="requestResetPassword()"
      >
        Réinitialiser le mot de passe 
      </atom-button>
    </template>
    <template v-else>
      <h2 class="reset-pass__form-heading" style="text-align:left !important">Cher utilisateur,</h2>
      <p class="reset-pass__form-paragraph" style="text-align:left !important">
        Nous avons bien reçu votre demande de réinitialisation de mot de passe. Nous vous avons envoyé un e-mail à l'adresse que vous avez fournie avec des instructions pour réinitialiser votre mot de passe. Si vous ne recevez pas l'e-mail dans les prochaines minutes, veuillez vérifier votre dossier de courrier indésirable ou contacter notre service d'assistance.
        Cordialement,
      </p>
    </template>
    <a
      class="reset-pass__form-link reset-pass__form-link--centered" 
      href="#"
      @click.prevent="$router.go(-1)"
    >
      Retour
    </a>
  </form>
  <div class="reset-pass__quote">
    <h1 class="reset-pass__quote-content">"Un manque de transparence se traduit par la méfiance et un profond sentiment d'insécurité."</h1>
    <span class="reset-pass__quote-author">- Dalai Lama, The Telegraph, 13 Mai 2012.</span>
  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {ref} from "vue";
const {dispatch} = useStore();
const email = ref("");
const isRequestSent = ref(false);
const requestResetPassword = async () => {
  if(email.value?.length){
    try {
      await dispatch("users/requestResetPassword", email.value);
      isRequestSent.value = true;
    } catch (err) {
      console.log(err);
    }
  }
}
</script>

<style lang="scss" scoped>
  .reset-pass{
    &__form{
      @apply w-full max-w-[500px] bg-white
      py-5 px-2 sm:px-4 md:px-6 lg:px-[80px]
      rounded-md;
      &-heading{
        @apply w-full font-bold
         text-center text-base
         mb-5;
      }
      &-paragraph{
        @apply text-base text-center
        mb-[68px];
      }
      &-input{
        @apply relative w-full py-2 px-4 border border-gray-100 placeholder-gray-base rounded-md;
      }
      &-button{
        @apply mb-5 mt-5 py-2 font-medium;
      }
      &-link{
        @apply text-base text-primary font-medium leading-4 inline-block;
        &--centered{
          @apply w-full text-center;
        }
      }
    }
    &__quote{
      @apply hidden lg:block p-5 bg-black/40 max-w-[600px] w-full;
      &-content{
        @apply text-white font-medium 
        text-[30px] xl:text-[36px];
      }
      &-author{
        @apply text-white font-medium text-sm;
      }
    }
  }
</style>